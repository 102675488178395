import Link from 'next/link'

import { cn } from '@/lib/utils'
import { useSession } from 'next-auth/react'

export function MainNav({ className, ...props }: React.HTMLAttributes<HTMLElement>) {
  const { status } = useSession()
  const isAuthed = status === 'authenticated'

  return (
    <nav className={cn('flex items-center space-x-4 lg:space-x-6', className)} {...props}>
      {isAuthed ?
        <>
          <Link
            href="/insights"
            className="text-sm font-medium text-muted-foreground transition-colors hover:text-primary"
          >
            Insights
          </Link>
          <Link
            href="/releases"
            className="text-sm font-medium text-muted-foreground transition-colors hover:text-primary"
          >
            Releases
          </Link>
          <Link
            href="/releases/create"
            className="text-sm font-medium text-muted-foreground transition-colors hover:text-primary"
          >
            New Release
          </Link>
        </>
       :
        <Link href="/login" className="text-sm font-medium text-muted-foreground transition-colors hover:text-primary">
          Login
        </Link>
      }
    </nav>
  )
}
