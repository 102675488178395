// import {
//   Avatar,
//   AvatarFallback,
//   AvatarImage,
// } from "@/registry/new-york/ui/avatar"
import { Me } from '@/server/routers/user'
import { signOut } from 'next-auth/react'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger
} from '@/components/DropdownMenu'

import { HamburgerMenuIcon } from '@radix-ui/react-icons'
import Link from 'next/link'

export function UserNav({ me }: { me: Me }) {
  // @ts-ignore
  const { email, name } = me
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <HamburgerMenuIcon className="ml-4 text-white/50 hover:text-white" width="20" height="20" />
        {/* // <Avatar className="h-8 w-8">
          //   <AvatarImage src="/avatars/01.png" alt="@shadcn" />
          //   <AvatarFallback>SC</AvatarFallback>
          // </Avatar> */}
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="end" forceMount>
        <DropdownMenuLabel className="font-normal">
          <div className="flex flex-col space-y-1">
            <p className="text-sm font-medium leading-none">{name}</p>
            <p className="text-xs leading-none text-muted-foreground">{email}</p>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuItem>Profile</DropdownMenuItem>
          <DropdownMenuItem>Billing</DropdownMenuItem>
          <DropdownMenuItem>
            <Link className="h-full w-full" href="/settings">
              Settings
            </Link>
          </DropdownMenuItem>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={() => signOut()}>
          Log out
          <DropdownMenuShortcut>⇧⌘Q</DropdownMenuShortcut>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
