import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'
import { Row } from '@tanstack/react-table'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const dateSortingFn = <TData extends Record<string, any>>(
  rowA: Row<TData>,
  rowB: Row<TData>,
  columnId: string
) => {
  const dateA = new Date(rowA.original[columnId] as string)
  const dateB = new Date(rowB.original[columnId] as string)
  return dateA.getTime() - dateB.getTime()
}
