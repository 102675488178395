import { sort, ISortByObjectSorter } from 'fast-sort'
import { get } from 'lodash'

type SortOrder = 'asc' | 'desc'
const compare = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' }).compare

export const naturalSortBy = <T>(data: T[], fields: string[], orderings?: SortOrder[]): T[] => {
  if (!data || data?.length === 0) return []

  if (!fields) throw new Error('Must provide an array of fields to sort by')

  const sortOrderings = orderings || fields.map(() => 'asc')
  const sortItems = fields.map(
    (field, index: number) =>
      ({
        [sortOrderings[index]]: (d: T) => get(d, field),
        comparer: compare
      }) as unknown as ISortByObjectSorter<T>
  )
  return sort(data).by(sortItems)
}
