import React, { useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import { nextTrpc } from '@/lib/trpc'
import { useLocalStorage } from '@rehooks/local-storage'
import CreateArtistModal from '@/components/modals/CreateArtistModal'
import { SelectOption } from '@/components/Select'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/Popover'
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem } from '@/components/Command'
import cn from '@/lib/util/cn'
import { Avatar, AvatarFallback } from '@/components/Avatar'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/Tooltip'
import { Role, TeamUser } from '@prisma/client'
import { MdCheck, MdOutlineAdd } from 'react-icons/md'

import { naturalSortBy } from '@/lib/util/naturalSortBy'

type Roles = 'logged-in' | 'logged-out' | 'on-team' | 'superadmin'
const isCorrectRole = (role?: Roles, me?: { role: Role; team: TeamUser[] } | null) => {
  if (!role) return true
  if (role === 'logged-in') return !!me
  if (role === 'logged-out') return !me
  if (me && me.role === Role.ADMIN) return true // superadmin supercedes all other roles
  if (role === 'on-team') return me && me.team && me.team.length > 0
  return false
}

export default function ArtistSelector() {
  const router = useRouter()
  const { data } = useSession()
  const [ isCreateArtistModalOpen, setIsCreateArtistModalOpen ] = useState(false)
  const { data: me } = nextTrpc.user.me.useQuery()
  // console.log('ME:', me)
  const { data: artists, isFetched: hasArtistsBeenFetched } = nextTrpc.artist.getAll.useQuery(undefined, {
    enabled: !!data?.user
  })

  const artistIdsWithAccess = useMemo(() => me?.team?.map((v) => v.artistId) || [], [ me ])
  const [ selectedArtistId, setSelectedArtistId ] = useLocalStorage<string>('selectedArtistId')
  const selectedArtist = useMemo(() => artists?.find((v) => v.id === selectedArtistId), [ selectedArtistId, artists ])
  const artistDropdownOptions = useMemo<SelectOption[]>(
    () =>
      naturalSortBy(
        (artists ?? [])
          .filter(({ id }) => isCorrectRole('superadmin', me) || artistIdsWithAccess.includes(id))
          .map(({ name, id }) => ({ label: name, value: id })),
        [ 'label' ]
      ),
    [ artists, me ]
  )
  const [ first, last ] = useMemo(() => (selectedArtist?.name ?? '').split(' '), [ selectedArtist?.name ])

  useEffect(() => {
    if (hasArtistsBeenFetched && (!selectedArtistId || !artists?.some((a) => a.id === selectedArtistId))) {
      setSelectedArtistId(artists?.[0]?.id || null)
    }
  }, [ artists, hasArtistsBeenFetched ])

  const hasTeams = artistDropdownOptions.length !== 0

  const handleArtistCreationComplete = (newArtistId: string) => {
    setSelectedArtistId(newArtistId)
    router.push('/team')
  }

  return (
    <div>
      <CreateArtistModal
        isOpen={isCreateArtistModalOpen}
        setIsOpen={setIsCreateArtistModalOpen}
        onComplete={handleArtistCreationComplete}
      />
      <div className="relative flex h-full flex-row flex-nowrap items-center justify-between lg:gap-5">
        {/* --- Artist Selector --- */}
        {hasTeams &&
          <Popover>
            <PopoverTrigger>
              <TooltipProvider delayDuration={100}>
                <Tooltip>
                  {/* TooltipTrigger is rendered as a button by default, but we are instead rendering it as a div to prevent buttons from being descendants of other buttons. */}
                  <TooltipTrigger asChild>
                    <div>
                      <Avatar className="opacity-50 hover:opacity-100">
                        <AvatarFallback>
                          {first?.charAt(0).toUpperCase()}
                          {last?.charAt(0).toUpperCase()}
                        </AvatarFallback>
                      </Avatar>
                    </div>
                  </TooltipTrigger>
                  <TooltipContent>{selectedArtist?.name || 'Artist Selection'}</TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </PopoverTrigger>
            <PopoverContent className="p-0">
              <Command>
                <CommandInput placeholder="Search artists..." />
                <CommandEmpty>No artists found.</CommandEmpty>
                <CommandGroup>
                  <CommandItem value="createArtist" onSelect={() => setIsCreateArtistModalOpen(true)}>
                    <MdOutlineAdd className="mr-2 h-4 w-4" />
                    Create Artist
                  </CommandItem>
                </CommandGroup>
                <CommandGroup className="max-h-[200px] overflow-auto">
                  {artistDropdownOptions.map((opt) =>
                    <CommandItem
                      key={opt.value}
                      value={opt.label}
                      onSelect={() => {
                        setSelectedArtistId(opt?.value ?? '')
                      }}
                    >
                      <MdCheck
                        className={cn('mr-2 h-4 w-4', selectedArtist?.id === opt.value ? 'opacity-100' : 'opacity-0')}
                      />
                      {opt.label}
                    </CommandItem>
                  )}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
        }
      </div>
    </div>
  )
}
